<template>
	<div class="phi-tucompra">
		<!--<div @click="run">
            <slot>
                <span class="action">{{ $t('action.pay') }}</span>
                <span class="value">{{ valor | currency }}</span>
            </slot>
        </div>-->

        <div class="gopay phi-media phi-card" v-if="valor > 0" @click="run">
            <!-- <mu-icon value="credit_card" class="phi-media-figure"></mu-icon> -->
            <div class="phi-media-body" align="center">{{ $t('action.pay') }}  {{ valor | currency }}</div>
            <!-- <mu-icon value="arrow_forward" class="phi-media-right"></mu-icon> -->
        </div>

        <form id="tucompraform" method="post" :action="url">
            <input type="hidden" name="usuario" :value="usuario" />
            <input type="hidden" name="factura" :value="factura" />
            <input type="hidden" name="valor" :value="valor" />
            <input type="hidden" name="descripcionFactura" :value="descripcionFactura" />
            <input type="hidden" name="nombreComprador" :value="nombreComprador" />
            <input type="hidden" name="apellidoComprador" :value="apellidoComprador" />
            <input type="hidden" name="tipoDocumentoComprador" :value="tipoDocumentoComprador" />
            <input type="hidden" name="documentoComprador" :value="documentoComprador" />
            <input type="hidden" name="correoComprador" :value="correoComprador" />
            <input type="hidden" name="telefonoComprador" :value="telefonoComprador" />
            <input type="hidden" name="paisComprador" :value="paisComprador" />
            <input type="hidden" name="ciudadComprador" :value="ciudadComprador" />
            <input type="hidden" name="direccionComprador" :value="direccionComprador" />
            <input type="hidden" name="celularComprador" :value="celularComprador" />

            <!-- Este dato es consultado por la URL de retorno (billing/tucompra/back) para saber que el pago fue hecho desde la app, e invocar el javascript necesario para procesar la respuesta en este componente -->
            <input type="hidden" name="campoExtra1" value="app" />
        </form>
	</div>
</template>

<script>
import app from '../../store/app.js';
export default {
	name: "phi-tucompra",

    data() {
        return {
            app,
        }
    },

    props: {
        label: {},
        url: {},
        usuario: {},
        factura: {},
        valor: {},
        descripcionFactura: {},
        nombreComprador: {},
        apellidoComprador: {},
        tipoDocumentoComprador: {},
        documentoComprador: {},
        correoComprador: {},
        telefonoComprador: {},
        paisComprador: {},
        ciudadComprador: {},
        direccionComprador: {},
        celularComprador: {}
    },

    mounted() {
        /* this.record.subscribe(this.commingPay); */
    },

    methods: {
        /* commingPay(data) {
            //console.log(data)
            if (data.codigoFactura == this.factura) {
                this.handleResults(data);
            } else {
                this.$emit("error", data);
            }
        }, */


    	run() {
            this.$emit("start");
            var isMobile = typeof cordova != "undefined";

            var handleMessage = event => {
                if (event.data.popup)  {
                    window.removeEventListener('message', handleMessage);
                    browserRef.close();
                    this.handleResults(event.data);
                }
            };
            window.addEventListener('message', handleMessage);

            var pageContent = document.getElementById("tucompraform").outerHTML+
            "<script type=\"text/javascript\">document.getElementById(\"tucompraform\").submit();"+String.fromCharCode('0x3C')+"/script>";

            // browsers deprecated top frame navigation to data URLS .. so now we need to do this the old fashion way
            // var pageContentUrl = 'data:text/html;base64,' + btoa(pageContent);  --> can't use this no longer for regular browsers
            var pageContentUrl = 'data:text/html;base64,' + btoa(pageContent);

            var browserRef = isMobile ?
                window.open(
                    pageContentUrl,
                    "_blank"
                )
            :
                window.open(
                    "",
                    "TuCompra",
                    "width=750, height=460"
                );

            if (!isMobile) {
                browserRef.document.write(pageContent);
            }

            browserRef.addEventListener('loadstart', e => {
            	if (/result=/.test(e.url)) {
            		var result = JSON.parse(decodeURIComponent( e.url.slice(e.url.indexOf("result=")+7) ));
            		browserRef.close();
                    this.handleResults(result);
            	}
            });
        },

        handleResults(result) {
            // Esto retorna un objecto así:
            // {"popup":{"ok":true,"msg":"Transacción éxitosa","postData":{"transaccionAprobada":"1","codigoFactura":"7vll1pmykuvi","valorFactura":"15000.0","codigoAutorizacion":"00","numeroTransaccion":"7163838","firmaTuCompra":"77889780fc4c62369184c937569c2795","campoExtra1":"app","campoExtra2":"","campoExtra3":"","campoExtra4":"","campoExtra5":"","campoExtra6":"","campoExtra7":"","campoExtra8":"","campoExtra9":"","metodoPago":"41","nombreMetodo":"Cuenta Ahorro/Corriente - TuCompra","finalizar":"Cuenta Ahorro/Corriente - TuCompra"}}}
            this.$emit("complete", result);
            if (result.popup.ok) {
                this.$emit("success", result.popup.ok);
            } else {
                this.$emit("error", result.popup.ok);
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.gopay {
    color: #fff;
    cursor: pointer;
    margin-top: 24px;
    align-items: center;
    background: #1565C0;
    // background-color: rgb(28, 137, 184);
    border-radius: 10px;
    transition: all .5s ease;

    &:hover {
        background: #0D47A1;
        // background-color: rgb(36, 180, 242);
    }

    .phi-media-body {
        font-size: 1.2em;
    }
}
</style>
