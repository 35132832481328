<template>
  <div class="phi-mercadopago">
    <select
      @change="openPopup($event.target.value); $event.target.value = ''"
      class="select-method"
      :disabled="!banks.length"
    >
      <option
        value
      >{{ isLoading ? 'cargando ...' : ( banks.length > 0 ? 'Seleccionar método de pago' : 'No hay opciones para pago en línea') }}</option>​
      <optgroup label="Tarjetas" v-if="isCCActive">
        <option value="cc">Tarjeta de crédito</option>
      </optgroup>​
      <optgroup label="Bancos" v-if="isPSEActive"> 
        <option
          v-for="bank in sanitizedBanks"
          :key="bank.id"
          :value="bank.id"
        >{{ bank.description }}</option>
      </optgroup>
    </select>
  </div>
</template>

<script>
import * as popup from "@/modules/ecommerce/helpers/popupWindow.js";

export default {
  name: "phi-mercadopago",

  props: {
    referenceId: {
      type: String,
      required: true
    },
    publicKey: {
      type: String,
      required: true
    },
    ammount: {
      type: [String, Number],
      required: true
    }
  },

  data() {
    return {
      banks: [],
      isLoading: false
    };
  },

  mounted() {
    this.fetchBanks();
  },

  computed: {
    v3Host() {
      return this.$store.state.api.host.replace(".api", "");
    },

    sanitizedBanks() {
      // hide repeated, and "0"
      let retval = [];
      let seenIds = {};
      this.banks.forEach(bank => {
        if (bank.id != "0" && typeof seenIds[bank.id] == "undefined") {
          seenIds[bank.id] = bank.id;
          retval.push(bank);
        }
      });
      return retval;
    },

    isCCActive() {
      
      let maicc = this.$store.getters.getSetting("mercadoPago");
      
      return maicc.mai.cc;
    },

    isPSEActive(){

      let maipse = this.$store.getters.getSetting("mercadoPago");
      return maipse.mai.pse;
    }

  },

  methods: {
    async fetchBanks() {
      this.isLoading = true;

      try {
        this.banks = await this.$store.state.api.get(
          `${this.v3Host}/billing/mercadopago/getmethods`
        );

        if (!Array.isArray(this.banks)) {
          this.banks = [];
        }
      } catch (err) {
        this.banks = [];
      }

      this.isLoading = false;
    },

    openPopup(methodName) {
      if (!methodName) {
        return;
      }
      popup.open(
        `${this.v3Host}/billing/mercadopago/popup?phidiasReference=${this.referenceId}&ammount=${this.ammount}&methodName=${methodName}`, // falta docType, docNumber, ...
        "_blank", //  no se puede usar _system porque no funcinaria window.opener.
        "height=768,width=1024,resizable=yes,toolbar=yes,status=yes,location=yes",
        this.onPopupMessage,
        this.onPopupClose
      );
    },

    onPopupMessage(message) {
      if (message.ok) {
        this.$emit("success", message);
      } else {
        this.$emit("error", message);
      }
    },

    onPopupClose() {
      this.$emit("error", {
        ok: false,
        message: "cancelled by user"
      });
    }
  }
};
</script>

<style lang="scss">
.phi-mercadopago {
  .select-method {
    display: block;
    margin: 12px auto;
    padding: 12px;
  }
}
</style>